.app-layout {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    min-height: 100vh;
    position: relative;
    background-color: rgb(239 239 244);
    
}

.app-layout .ant-drawer-body {
    background-color: rgb(239 239 244) !important;
}

.app-layout .app-header {
    position: relative;
    margin: 0;
    height: 100vh;
    font-weight: 100;
    background: radial-gradient(#3976a2, #1f1013);
    -webkit-overflow-Y: hidden;
    -moz-overflow-Y: hidden;
    -o-overflow-Y: hidden;
    overflow-y: hidden;
    -webkit-animation: fadeIn 1 1s ease-out;
    -moz-animation: fadeIn 1 1s ease-out;
    -o-animation: fadeIn 1 1s ease-out;
    animation: fadeIn 1 1s ease-out;
    height: 250px;
    border: 1px solid;
}

.app-layout .profiles {
    display: flex;
    align-items: center;
    padding-left: 30px;
    position: absolute;
    z-index: 999;
    top: 192px;
    width: 100%;
    /*
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-45%);
    */
}

.app-layout .logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -70%);
}

.app-layout .logo img {
    width: 100px;
}


.app-layout .profiles .avatar {
    width: 110px;
    height: 110px;
    z-index: 1;
    background-color: white;
    border-radius: 100% 100%;
    box-shadow: 0px 12px 20px -20px black;
}

.app-layout .profiles .avatar img {
    border-radius: 100% 100%;
    width: 110px;
    height: 110px;
    border: 3px solid white;
}

.app-layout .profiles .profileInfo {

    width: 100%;

    margin-left: -15px;
    margin-right: 15px;
    box-shadow: 0px 12px 20px -20px black;
}


.app-layout .profiles .profileInfo .name {

    font-size: 18px;
    color: #5e5e5e;
    font-weight: 500;
    background: #dedede;
    border-radius: 0 7px 0 0;
}

.app-layout .profiles .profileInfo .companyTitle {

    font-size: 12px;
    color: #666;
    font-weight: 400;
    background-color: #e5e5e5;
    border-radius: 0 0 7px 0;
}

.app-layout .profiles .profileInfo .title {

    font-size: 16px;
    color: #fff;
    font-weight: 400;
    background-color: #b9b9b9
}


.app-middle {
    margin-top: 60px;
    background-color: rgb(239 239 244);
    margin-left: 15px;
    margin-right: 15px;
}

.app-middle .items a {
    display: flex;
    color: #444;
    padding-top: 2px;
    padding-bottom: 2px;
}

.app-middle .items .icon {
    margin-right: 15px;


}

.app-middle .items .value {

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.app-brand {
    margin-top: 80px;
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
    padding: 5px;
    background-color: #fff;
}


/*

Menu & Quick menu

*/
.boxshadow {
    -webkit-box-shadow: 13px 10px 24px -14px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 13px 10px 24px -14px rgba(0, 0, 0, 0.25);
    box-shadow: 13px 10px 24px -14px rgba(0, 0, 0, 0.25);
}

.light {
    position: absolute;
    width: 0px;
    opacity: .75;
    background-color: white;
    box-shadow: #e9f1f1 0px 0px 20px 2px;
    opacity: 0;
    top: 100vh;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
}

.x1 {
    -webkit-animation: floatUp 4s infinite linear;
    -moz-animation: floatUp 4s infinite linear;
    -o-animation: floatUp 4s infinite linear;
    animation: floatUp 4s infinite linear;
    -webkit-transform: scale(1.0);
    -moz-transform: scale(1.0);
    -o-transform: scale(1.0);
    transform: scale(1.0);
}

.x2 {
    -webkit-animation: floatUp 7s infinite linear;
    -moz-animation: floatUp 7s infinite linear;
    -o-animation: floatUp 7s infinite linear;
    animation: floatUp 7s infinite linear;
    -webkit-transform: scale(1.6);
    -moz-transform: scale(1.6);
    -o-transform: scale(1.6);
    transform: scale(1.6);
    left: 15%;
}

.x3 {
    -webkit-animation: floatUp 2.5s infinite linear;
    -moz-animation: floatUp 2.5s infinite linear;
    -o-animation: floatUp 2.5s infinite linear;
    animation: floatUp 2.5s infinite linear;
    -webkit-transform: scale(.5);
    -moz-transform: scale(.5);
    -o-transform: scale(.5);
    transform: scale(.5);
    left: -15%;
}

.x4 {
    -webkit-animation: floatUp 4.5s infinite linear;
    -moz-animation: floatUp 4.5s infinite linear;
    -o-animation: floatUp 4.5s infinite linear;
    animation: floatUp 4.5s infinite linear;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    left: -34%;
}

.x5 {
    -webkit-animation: floatUp 8s infinite linear;
    -moz-animation: floatUp 8s infinite linear;
    -o-animation: floatUp 8s infinite linear;
    animation: floatUp 8s infinite linear;
    -webkit-transform: scale(2.2);
    -moz-transform: scale(2.2);
    -o-transform: scale(2.2);
    transform: scale(2.2);
    left: -57%;
}

.x6 {
    -webkit-animation: floatUp 3s infinite linear;
    -moz-animation: floatUp 3s infinite linear;
    -o-animation: floatUp 3s infinite linear;
    animation: floatUp 3s infinite linear;
    -webkit-transform: scale(.8);
    -moz-transform: scale(.8);
    -o-transform: scale(.8);
    transform: scale(.8);
    left: -81%;
}

.x7 {
    -webkit-animation: floatUp 5.3s infinite linear;
    -moz-animation: floatUp 5.3s infinite linear;
    -o-animation: floatUp 5.3s infinite linear;
    animation: floatUp 5.3s infinite linear;
    -webkit-transform: scale(3.2);
    -moz-transform: scale(3.2);
    -o-transform: scale(3.2);
    transform: scale(3.2);
    left: 37%;
}

.x8 {
    -webkit-animation: floatUp 4.7s infinite linear;
    -moz-animation: floatUp 4.7s infinite linear;
    -o-animation: floatUp 4.7s infinite linear;
    animation: floatUp 4.7s infinite linear;
    -webkit-transform: scale(1.7);
    -moz-transform: scale(1.7);
    -o-transform: scale(1.7);
    transform: scale(1.7);
    left: 62%;
}

.x9 {
    -webkit-animation: floatUp 4.1s infinite linear;
    -moz-animation: floatUp 4.1s infinite linear;
    -o-animation: floatUp 4.1s infinite linear;
    animation: floatUp 4.1s infinite linear;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
    left: 85%;
}

button:focus {
    outline: none;
}

@-webkit-keyframes floatUp {
    0% {
        top: 100vh;
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        top: 0vh;
        opacity: .8;
    }

    75% {
        opacity: 1;
    }

    100% {
        top: -100vh;
        opacity: 0;
    }
}

@-moz-keyframes floatUp {
    0% {
        top: 100vh;
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        top: 0vh;
        opacity: .8;
    }

    75% {
        opacity: 1;
    }

    100% {
        top: -100vh;
        opacity: 0;
    }
}

@-o-keyframes floatUp {
    0% {
        top: 100vh;
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        top: 0vh;
        opacity: .8;
    }

    75% {
        opacity: 1;
    }

    100% {
        top: -100vh;
        opacity: 0;
    }
}

@keyframes floatUp {
    0% {
        top: 100vh;
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    50% {
        top: 0vh;
        opacity: .8;
    }

    75% {
        opacity: 1;
    }

    100% {
        top: -100vh;
        opacity: 0;
    }
}

.header {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Roboto', sans-serif;
    font-weight: 200;
    color: white;
    font-size: 2em;
}

#head1,
#head2,
#head3,
#head4,
#head5 {
    opacity: 0;
}

#head1 {
    -webkit-animation: fadeOut 1 5s ease-in;
    -moz-animation: fadeOut 1 5s ease-in;
    -o-animation: fadeOut 1 5s ease-in;
    animation: fadeOut 1 5s ease-in;
}

#head2 {
    -webkit-animation: fadeOut 1 5s ease-in;
    -moz-animation: fadeOut 1 5s ease-in;
    -o-animation: fadeOut 1 5s ease-in;
    animation: fadeOut 1 5s ease-in;
    -webkit-animation-delay: 6s;
    -moz-animation-delay: 6s;
    -o-animation-delay: 6s;
    animation-delay: 6s;
}

#head3 {
    -webkit-animation: fadeOut 1 5s ease-in;
    -moz-animation: fadeOut 1 5s ease-in;
    -o-animation: fadeOut 1 5s ease-in;
    animation: fadeOut 1 5s ease-in;
    -webkit-animation-delay: 12s;
    -moz-animation-delay: 12s;
    -o-animation-delay: 12s;
    animation-delay: 12s;
}

#head4 {
    -webkit-animation: fadeOut 1 5s ease-in;
    -moz-animation: fadeOut 1 5s ease-in;
    -o-animation: fadeOut 1 5s ease-in;
    animation: fadeOut 1 5s ease-in;
    -webkit-animation-delay: 17s;
    -moz-animation-delay: 17s;
    -o-animation-delay: 17s;
    animation-delay: 17s;
}

#head5 {
    -webkit-animation: finalFade 1 5s ease-in;
    -moz-animation: finalFade 1 5s ease-in;
    -o-animation: finalFade 1 5s ease-in;
    animation: finalFade 1 5s ease-in;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 22s;
    -moz-animation-delay: 22s;
    -o-animation-delay: 22s;
    animation-delay: 22s;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    80% {
        opacity: .9;
    }

    100% {
        opacity: 0;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    80% {
        opacity: .9;
    }

    100% {
        opacity: 0;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    80% {
        opacity: .9;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    80% {
        opacity: .9;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes finalFade {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    80% {
        opacity: .9;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes finalFade {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    80% {
        opacity: .9;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes finalFade {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    80% {
        opacity: .9;
    }

    100% {
        opacity: 1;
    }
}

@keyframes finalFade {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    80% {
        opacity: .9;
    }

    100% {
        opacity: 1;
    }
}

:root {
    --dark: #333;
    --light: #fff;
    --neutral: #f6f6f6;
    --neutral-dark: #d1d1d1;
    --color: #3f4296;
    --color-light: #1eacd2;
    --color-dark: #2719cd;
    --font-stack: "Josefin Sans", "Montserrat", "sans-serif";

}




.menu__button {
    position: fixed;
    --x: -50%;
    --y: 0;
    --z: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(to right, var(--color-light), var(--color-dark));
    padding: 10px 20px;
    border-radius: 28px;
    box-shadow: 0 2px 40px -10px var(--color);
    color: var(--light);
    font-size: 16px;
    white-space: nowrap;

    bottom: 15px;
    left: 50%;
    transform: translate3d(var(--x), var(--y), var(--z));
    cursor: pointer;
    z-index: 105;
    transition: 0.3s cubic-bezier(0.33, 1, 0.53, 1);
    border: 0
}

.menu__button[hidden] {
    display: block !important;
    --y: 200%;
}

.menu__button div {
    display: flex;
    align-items: center;
    width: 21px;
    height: 21px;
    margin-right: 3px;
}

.menu__button div div {
    display: table;
    height: 1px;
    background: var(--light);
    box-shadow: 0 4px 0 var(--light), 0 -4px 0 var(--light);
}

.menu__overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 100;
}

.menu__body {
    --x: 0;
    --y: 0;
    --z: 0;
    display: block;
    width: 100%;
    background: var(--light);
    padding-bottom: 15px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0px -9px 50px -30px black;
    font-family: "Montserrat", sans-serif;
    position: fixed;
    bottom: 0;
    z-index: 999;
    transform: translate3d(var(--x), var(--y), var(--z));
    transition: 0.3s cubic-bezier(0.33, 1, 0.53, 1);
}

.menu__body[hidden] {
    display: block !important;
    --y: 150%;
}

.menu__body>*:not(:last-child) {
    border-bottom: 2px solid var(--neutral);
}

.menu__header {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
}

.menu__header label div {
    width: 15px;
    height: 15px;

    border-radius: 50%;
    position: relative;
    transform: rotate(5.5rad);
}



.menu__header p {
    font-size: 18px;
    margin-bottom: 0 !important;
    font-weight: bold;
    vertical-align: center;
    white-space: nowrap;
}

.menu__header h3 {
    font-weight: normal;
}

.menu__header button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: var(--color);
    border-radius: 50%;
    cursor: pointer;
    border: 0;
    color: #fff;
    padding-top: 2px;
    font-weight: bold;
}



.menu__links {
    display: flex;
    padding: 10px 15px;
    font-weight: bold;
    overflow-x: auto;
    text-align: center;
    justify-content: space-around;
}

.menu__links div {
    flex: 1 1 1px;
    padding: 10px 8px;
    color: var(--dark);
    text-decoration: none;
    white-space: nowrap;
}

.menu__contact {
    display: flex;
    padding: 8px;
}

.menu__contact .buttonLink i {
    margin: 5px;
    font-size: 24px;
}

.menu__contact .buttonLink {
    flex: 1 1 30%;
    padding: 5px;
    margin: 5px;
    border-radius: 8px;
    background: var(--neutral);
    color: var(--color);
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    border: 0;
    text-align: center;
    align-items: center;
    z-index: 999;
}

.ant-drawer-header .anticon-close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: var(--color);
    border-radius: 50%;
    cursor: pointer;
    border: 0;
    color: #fff;
    padding-top: 2px;
    font-weight: bold;
}

.shareBlock {
    margin-top: 5px;
    display: flex;
    width: -moz-fit-content;
    width: 100%;
    border-radius: 7px;
    z-index: 1001;
    /* margin: 15px; */
    justify-content: space-around;
    align-items: center;
    padding: 15px;
    flex-direction: row;
    padding-top: 0 !important;
}

.qrCustomise {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-float-btn-circle,
.ant-float-btn-body {
    height: 70px !important;
    width: 70px !important
}


.ant-float-btn-primary,
.ant-float-btn-primary .ant-float-btn-body:hover,
.ant-float-btn-primary .ant-float-btn-body  {
    background-color: var(--color);
    background: linear-gradient(to right, var(--color-light), var(--color-dark));
    box-shadow: 0 2px 40px -10px #000000;
}


.ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon {
    width: 28px !important;
    font-size: 28px !important;
}